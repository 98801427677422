import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  decryptData,
  encryptData,
  getClientId,
} from '../../../foundation/utils/api';
import env_constants from '../../../internals/env/env_constants.json';

export const addIpPermission = createAsyncThunk(
  'permissions/update',
  async (apiParams: {
    data: {
      userId: string;
      sessionId: string;
      ipAddress: string;
    };
    token: string;
    clientId: string | undefined;
  }) => {
    const encryptedData = encryptData(apiParams.data);
    const response = await axios.post(
      `${env_constants.APP_STORE_API}/User/ppf-ip-permission`,
      encryptedData,
      {
        headers: {
          Authorization: `Bearer ${apiParams.token}`,
          'Content-type': 'application/json',
          clientid: getClientId(apiParams.clientId),
        },
      },
    );
    const data = decryptData(response.data);
    return data;
  },
);
