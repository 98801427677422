import { Button, Input, message, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Image from '../../foundation/assets/images/connectivity.png';
import { useAppDispatch } from '../../store/hooks';
import {
  selectClientId,
  selectClientIp,
  selectUser,
} from '../authentication/redux/selectors';
import { addIpPermission } from './redux/async_thunks';

const Permissions = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [ip, setIp] = useState<string | undefined>(undefined);
  const clientIp = useSelector(selectClientIp);
  const clientId = useSelector(selectClientId);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (clientIp?.ipv4) {
      setIp(clientIp?.ipv4);
    }
  }, [clientIp]);

  const permitBtnHandler: React.MouseEventHandler<HTMLElement> = async (e) => {
    if (ip && user) {
      try {
        e.preventDefault();
        setIsLoading(true);

        // Validate the IP address
        if (!/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test(ip)) {
          message.error('Please enter a valid IP address.');
          return;
        }

        await dispatch(
          addIpPermission({
            token: user.app_store_token.token,
            clientId: clientId,
            data: {
              ipAddress: ip,
              sessionId: user.session_id,
              userId: user.user_id,
            },
          }),
        ).unwrap();

        // Display success message
        notification.success({
          message: 'Success!',
          description:
            'IP address permitted to use Property Pathfinder test environment',
        });
      } catch (error: any) {
        let errorMessage = 'Something went wrong.';
        if (error && error.message) {
          errorMessage = error.message;
        }
        notification.error({
          message: 'Error',
          description: errorMessage,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleIpChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const inputValue = event.target.value;
    const filteredValue = inputValue.replace(/[^0-9.]/g, ''); // Remove non-digit and non-period characters
    setIp(filteredValue);
  };

  return (
    <div className="l-permissions">
      <div className="l-permissions__form-wrapper">
        <div className="l-permissions__form">
          <div className="l-permissions__form-heading">Identity Management</div>
          <div className="l-permissions__form-subheading">
            {`Enter the IP you'll wish to permit on Property Pathfinder test
          environment.`}
          </div>
          <div>
            <Input value={ip} onChange={handleIpChange} pattern="^[0-9.]*$" />
          </div>

          <Button
            className="l-permissions__form-btn"
            type={'primary'}
            disabled={!ip || isLoading}
            onClick={permitBtnHandler}
            loading={isLoading}
          >
            Permit
          </Button>
        </div>
      </div>
      <div
        className="l-permissions__image-wrapper"
        style={{
          backgroundImage: `url(${Image})`,
        }}
      ></div>
    </div>
  );
};

export default Permissions;
