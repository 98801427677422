import {
  DownOutlined,
  FileProtectOutlined,
  HomeOutlined,
  KeyOutlined,
  LogoutOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Dropdown, Layout, Menu, notification } from 'antd';
import React, { ReactNode, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Logo from '../../../foundation/assets/images/logo_black_small.png';
import { RouteType } from '../../../foundation/config/routes';
import useMedia from '../../../foundation/utils/useMedia';
import env_constants from '../../../internals/env/env_constants.json';
import { logout } from '../../authentication/redux/async_thunks';
import {
  selectClientId,
  selectEnvironment,
  selectUser,
} from '../../authentication/redux/selectors';
import MobileMenu from './mobile_menu/MobileMenu';

const { Header: LayoutHeader } = Layout;

const Header = ({
  children,
  routeConfig,
}: {
  children: ReactNode;
  routeConfig: RouteType | undefined;
}) => {
  const dispatch = useDispatch();
  const isMediumScreen = useMedia('(min-width: 767px)');
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const clientId = useSelector(selectClientId);

  const environment = useSelector(selectEnvironment);

  const [showMenu, setShowMenu] = useState(false);

  const handleLogout = useCallback(async () => {
    try {
      if (user) {
        await dispatch(
          logout({
            data: {
              userId: user.user_id,
              sessionId: user.session_id,
              appId: env_constants.APP_ID,
            },
            token: user.app_store_token.token,
            clientId: clientId,
          }),
          // @ts-ignore
        ).unwrap();
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Error!',
        description: 'Something went wrong while logging out',
      });

      sessionStorage.clear();
      navigate('/');
    }
  }, []);

  const menu = (
    <Menu style={{ flex: 'auto', minWidth: 0 }}>
      <Menu.Item
        key="1"
        icon={<HomeOutlined />}
        onClick={() => {
          setShowMenu(false);
          navigate('/dashboard');
        }}
      >
        Home
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={<TeamOutlined />}
        onClick={() => {
          setShowMenu(false);
          navigate('/sessions');
        }}
      >
        Sessions
      </Menu.Item>
      {user?.app_store_role_id === 9 && (
        <Menu.Item
          key="3"
          icon={<KeyOutlined />}
          onClick={() => {
            setShowMenu(false);
            navigate('/app-access');
          }}
        >
          App Access
        </Menu.Item>
      )}
      <Menu.Item
        key="4"
        icon={<FileProtectOutlined />}
        onClick={() => {
          setShowMenu(false);
          navigate('/permissions');
        }}
        disabled={environment !== 'Test'}
      >
        Permissions
      </Menu.Item>
      <Menu.Item key="5" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <LayoutHeader className="c-app-layout__header">
      <div className="c-app-layout__header-left-side">
        <div
          className="c-app-layout__header-logo"
          onClick={() => {
            navigate('/');
          }}
        >
          <img src={Logo} alt="Dashdot Application Store" />
        </div>
      </div>
      <div className="c-app-layout__header-right-side ">
        {isMediumScreen ? (
          <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
            <div className="c-app-layout__header-user">
              <div style={{ marginRight: '15px' }}>
                <Avatar
                  src={user?.user_picture}
                  icon={<UserOutlined />}
                  style={{ marginRight: '10px' }}
                />
                <strong>{`${user?.user_first_name} ${user?.user_last_name}`}</strong>
              </div>
              <div style={{ fontSize: 12 }}>
                <DownOutlined />
              </div>
            </div>
          </Dropdown>
        ) : (
          <MobileMenu
            title={
              <>
                <div>
                  <Avatar
                    src={user?.user_picture}
                    icon={<UserOutlined />}
                    style={{ marginRight: '10px' }}
                  />
                  <strong>{`${user?.user_first_name} ${user?.user_last_name}`}</strong>
                </div>
              </>
            }
            visible={showMenu}
            onClose={setShowMenu}
          >
            {menu}
          </MobileMenu>
        )}
      </div>
    </LayoutHeader>
  );
};

export default Header;
