import { useGoogleLogin } from '@react-oauth/google';
import { Button } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import GoogleLogo from '../../../foundation/assets/svgs/Google.svg';
import { selectGoogleScope } from '../redux/selectors';

type GoogleAuthButtonProps = {
  handleGoogleLogin: (response: any) => void;
  isLoading: boolean;
};

const GoogleAuthButton = ({
  handleGoogleLogin,
  isLoading,
}: GoogleAuthButtonProps) => {
  const googleScope = useSelector(selectGoogleScope);

  const googleLogin = useGoogleLogin({
    onSuccess: handleGoogleLogin,
    flow: 'auth-code',
    scope: googleScope,
  });

  return (
    <Button
      onClick={googleLogin}
      className="l-login__google-button"
      loading={isLoading}
    >
      <GoogleLogo /> Continue with Google
    </Button>
  );
};

export default GoogleAuthButton;
