import { GoogleOAuthProvider } from '@react-oauth/google';
import { notification, Spin } from 'antd';
import CryptoES from 'crypto-es';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Logo from '../../../foundation/assets/images/dashdot_logo_name_black.png';
import { getSessionClientID } from '../../../foundation/utils/api';
import { useAppDispatch } from '../../../store/hooks';
import { fetchClientData, login } from '../redux/async_thunks';
import {
  selectClientIp,
  selectGoogleClientId,
  selectGoogleSHA512Key,
} from '../redux/selectors';
import GoogleAuthButton from './GoogleAuthButton';

const Login = () => {
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useAppDispatch();

  const client_ip: any = useSelector(selectClientIp);

  const navigate = useNavigate();

  const googleClientId = useSelector(selectGoogleClientId);

  const googleSHA512Key = useSelector(selectGoogleSHA512Key);

  const errorHandler = (message?: any, description?: any) => {
    notification.error({
      message: message ? message : <strong>Error</strong>,
      description: description
        ? description
        : `Something went wrong, please try again later.`,
    });
    setIsLoading(false);
    sessionStorage.clear();
    navigate('/');
  };

  const getData = async () => {
    try {
      await dispatch(
        fetchClientData(), // @ts-ignore
      ).unwrap();

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      errorHandler();
    }
  };

  useEffect(() => {
    // passing getData method to the lifecycle method
    getData();
  }, []);

  const handleGoogleLogin = async (response: any) => {
    try {
      setIsLoading(true);
      if (response.error) {
        return setIsLoading(false);
      }

      if (googleClientId && googleSHA512Key) {
        const hash = CryptoES.HmacSHA512(response.code, googleSHA512Key);
        const authroization_code_hash = CryptoES.enc.Base64.stringify(hash);
        const signature = `${authroization_code_hash};${getSessionClientID()};${client_ip.ipv4}`;
        const requestBody = {
          googleAuth: {
            signature,
            code: response.code,
          },
        };

        await dispatch(login({ data: requestBody })).unwrap();
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      errorHandler('Error', error?.message);
    }
  };

  return (
    <div className="l-login">
      <div className="l-login__form-wrapper">
        <img src={Logo} alt="" className="l-login__form-logo" />
        <div className="l-login__form-title">Dashdot Application Store</div>
        <div className="l-login__form-msg">Login To Your Account</div>
        <div className="l-login__auth-container">
          {isLoading ? (
            <div className="l-login__loading-container">
              <Spin size="large" />
              <div className="l-login__loading-text">
                Setting up secure login...
              </div>
            </div>
          ) : googleClientId ? (
            <GoogleOAuthProvider clientId={googleClientId}>
              <GoogleAuthButton
                handleGoogleLogin={handleGoogleLogin}
                isLoading={isLoading}
              />
            </GoogleOAuthProvider>
          ) : (
            <div className="l-login__error-text">
              Unable to initialize Google login. Please try again later.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Login;
